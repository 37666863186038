@import url(https://fonts.googleapis.com/css2?family=Dongle:wght@300&display=swap);

@charset "utf-8";

.cf::after {
  content: "";
  display: block;
  clear: both;
}
.display-width{
  width: 100% !important;
}
html{
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  min-height: 100%;
  font-family: 'Dongle', sans-serif;
	font-size: 62.5%;
  /*background: #FF8300;*/

}
body{
  overflow-x: hidden;
  background: url(/static/media/bg_pc.7b46fbca.png) no-repeat top/cover;
}
.mv-block{
  overflow-x: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}
.mv-block .img{
  position: relative;
  top: 0;
  left: 50%;
  margin: 0 0 0 -600px;
  width: 1200px;
  height: auto;
  z-index: 2;
}
.mv-block .img img{
  width: 100%;
  height: auto;
}
#root,.root{/*中の枠*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  width: 600px;
  margin: 0 auto 0;
  padding: 30px 0;
  background: rgba(255, 131, 0, .9);
  text-align: center;
  border-bottom: 20px solid #FCDF51;
}
.logo {
  position:fixed;	
  top:10px;
  left:10px;
  width: 100px;
}
.ttl{
  margin-top: 0px;
  color: #FCDF51;
  font-family: 'Dongle', sans-serif;
  font-size: 4.6rem;
}
.l-txt{
  margin-top: 10px;
  color: #FCDF51;
  font-family: 'Dongle', sans-serif;
  font-size: 3.6rem;
}
.s-txt,.address{
  margin-top: 0px;
  font-size: 2.5rem;
  color: #FCDF51;

}
.mint-cnt .cnt-block + .s-txt{ /*max mint*/
  margin-top: 0px;
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Dongle', sans-serif;
}
.s-txt + .s-txt{/*price*/
  margin-top: .5vw;
  font-size: 2.6rem;
  font-family: 'Dongle', sans-serif;
}
.total-supply{
  margin-top: 0px;
  color: #000;
  font-family: 'Dongle', sans-serif;
  font-size: 3.6rem;
  color: #FCDF51;
}
.btn-typ01,
.btn-typ02,
.btn-typ03{
  margin-top: 30px;
}
.btn-typ01-a
{
  margin-top: 30px;
}
.btn-typ01 button,
.btn-typ02 button,
.btn-typ03 button{
  display: block;
  min-width: 230px;
  margin: 0 auto;
  padding: 12px 38px;
  background: #FCDF51;
  border: none;
  border-radius: 50px;
  font-family: 'Dongle', sans-serif;
  color: #FF8300;
  font-weight: bold;
  font-size: 3.6rem;
  transition: .3s all;
  letter-spacing: .5rem;
}
.btn-typ01-a button
{
  display: block;
  min-width: 230px;
  margin: 0 auto;
  padding: 12px 38px;
  background: #FCDF51;
  border: none;
  border-radius: 50px;
  font-family: 'Dongle', sans-serif;
  color: #FF8300;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s all;
  letter-spacing: .5rem;
}
.btn-typ01 button:hover,
.btn-typ02 button:hover,
.btn-typ03 button:hover{
  background: rgb(180,220,210);
  transition: .6s all;
}
.btn-typ01-a button:hover
{
  background: rgb(180,220,210);
  transition: .6s all;
}
.cnt-block{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  margin: 20px auto 0;
}
.cnt-block .num{
  width: 140px;
  margin: 0 50px;
  color: #FCDF51;
  font-family: 'Dongle', sans-serif;
  font-size: 4.8rem;
}
.cnt-block button{
  display: block;
  width: 40px;
  height: 40px;
  background: #FCDF51;
  border: none;
  border-radius: 50%;
  color: #FF8300;
  font-family: 'Dongle', sans-serif;
  font-weight: bold;
  font-size: 3rem;
  transition: .3s all;
}
.cnt-block #minus10{
  margin-right: 10px;
}
.cnt-block #plus10{
  margin-left: 10px;
}
.cnt-block button:hover{
  background: #9a9b99;
  transition: .6s all;
}
.bg-slide{
  overflow: hidden;
  height: 30vh;
  margin-top: 70vh;
}
.bg-slide li{
  width: 30vh !important;
  margin: 0 20px;
}
.bg-slide li img{
  width: 100% !important;
  height: auto !important;
}


@media only screen and (max-width: 768px) {
/* -------------------------------- 
common
-------------------------------- */
body{
  overflow-x: hidden;
  background: url(/static/media/bg_sp.e5d68afb.png) no-repeat top/cover;
}
#root,.root{
  width: 90vw;
}
.bg-slide{
  height: 20vh;
  margin-top: 80vh;
}
.bg-slide li{
  width: 20vh !important;
  margin: 0;
}
.address{
  width: 80vw;
  margin: 10px auto 0;
}
.cnt-block .num{
  margin: 0 25px;
}
.cnt-block button{
  width: 30px;
  height: 30px;
  font-size: 1.4rem;
}
.cnt-block #minus10{
  margin-right: 10px;
}
.cnt-block #plus10{
  margin-left: 10px;
}

}


@media only screen and (max-width: 375px) {
/* -------------------------------- 
common
-------------------------------- */
#root .img,.root .img{
  width: 85vw;
  margin: 0 auto;
}
.cnt-block .num{
  margin: 0 15px;
}
.btn-typ01 button,
.btn-typ02 button,
.btn-typ03 button{
  font-size: 1.2rem;
}

}




